import { h, Component } from 'preact'
import './App.css'
import graphql from 'graphql.js';

function QuestLink(props) {
	return <a href="#">{props.quest.description}</a>
}

function QuestList(props) {
	const questItems = props.quests.map(questRow => <li><QuestLink quest={questRow.quest} /></li>)
	return <ul>
		{questItems}
	</ul>
}

const url = process.env.GRAPHQL_API || "http://localhost:3013/graphql";

export default class App extends Component {

	constructor() {
		super();
		this.state = {
			quests: [],
			accessToken: "",
			username: ""
		};

		this.graph = this.createGraph();
		this.login = this.login.bind(this);
	}

	createGraph(accessToken) {

		return graphql(`${url}`, {
			asJSON: true, headers: {
				// headers
				"Access-Token": accessToken
				// OR "Access-Token": () => "some-access-token"
			}
		});
	}

	showQuests() {
		const listQuestQuery = `
			query test {
				questsForLoggedInUser {
					id
					quest {
						id
						description
					}
				}
			}
		`;

		const call = this.graph(listQuestQuery);

		call()
			.then((result) => {
				const quests = result.questsForLoggedInUser;
				if (quests) {
					this.setState({
						quests
					});
				}
			})
	}

	login(username) {
		const QUERY = `query loginUser {
				loginUser(username : "${username}")
			}`;		
		
		const call = this.graph(QUERY);
		const res = call();
		
		res.then((result) => {
				this.setState({
					quests : []
				});

				const accessToken = result.loginUser;
				
				this.setState({
					accessToken
				});

				this.graph = this.createGraph(accessToken);
				this.showQuests();
				// console.log(this.state)

			})
			.catch(function (err) {
				console.log("=> ");
				console.log(err);
			});
	}

	render() {

		// alert(process.env.GRAPHQL_API);



		return (
			<div>
				<h1>CrowdQuest lite</h1>
				
				<div>
					<input type="text" placeholder="Enter username" value={this.state.username} onChange={(evt) => this.setState({ username: evt.target.value })} />
					<input type="button" value="Login" onClick={() => this.login(this.state.username)} />
				</div>

				{ this.state.quests.length > 0 && <div>
					<h1>My quests</h1>
					<QuestList quests={this.state.quests} />
				</div>}

				{ this.state.quests.length === 0 && <div>
						<strong>No quests found</strong>
					</div>}
			</div>
		)
	}
}